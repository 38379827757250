

<template>
    <router-view />
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'

// 主题色部分修改
onMounted(()=>{
    document.body.style.setProperty('--el-color-primary', 'var(--el-color-warning)');
    document.body.style.setProperty('--el-color-primary-light-3', 'var(--el-color-warning)');
    document.body.style.setProperty('--el-color-primary-light-5', '#fff');
    document.body.style.setProperty('--el-color-primary-light-7', 'var(--el-color-warning)');
    
    document.body.style.setProperty('--el-color-primary-dark-2', 'var(--el-border-color-lighter)');
    // 按钮背景色
    document.body.style.setProperty('--el-color-primary-light-9', 'var(--el-color-black)');
    document.body.style.setProperty('--el-menu-active-color', 'var(--el-color-warning)');
})

</script>
