class LocalStorageManager {

    // 刷新后要保持的当前页面
    static isLoginKey: string = "isLogin";
    // 当前登陆人用户名
    static adminUserNameKey: string = "adminUserName";


    static setIsLogin(isLogin: boolean): void {
        localStorage.setItem(LocalStorageManager.isLoginKey, `${isLogin}`);
    }

    static loginState(): boolean {
        return Boolean(localStorage.getItem(LocalStorageManager.isLoginKey));
    }

    static removeIsLogin(): void {
        localStorage.removeItem(LocalStorageManager.isLoginKey);
    }

    static setAdminUserName(adminUserName: string): void {
        localStorage.setItem(LocalStorageManager.adminUserNameKey, adminUserName)
    }

    static adminUserName(): string {
        return String(localStorage.getItem(LocalStorageManager.adminUserNameKey));
    }

    static removeAdminUserName(): void {
        localStorage.removeItem(LocalStorageManager.adminUserNameKey);
    }

}

export default LocalStorageManager;