import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import LocalStorageManager from "../ts/LocalStorageManager"


const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/*webpackChunkName:'Login'*/ '../views/AdminLoginView.vue')
  },
  {// 主界面
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "Index" */ '../views/Index.vue'),
    // 进入index，默认进入什么页面
    redirect: '/paymentOrder',
    // 二级路由
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/*webpackChunkName:'Dashboard'*/ '../views/DashboardView.vue')
      },
      {
        path: '/wallet',
        name: 'wallet',
        redirect: '/settlement',
        children: [
          {
            path:'/freezing',
            name: 'freezing',
            component: () => import(/* webpackChunkName: "Settlement" */ '../views/MerchantAmountFreezeRecordView.vue')
          },
          {
            path: '/settlement',
            name: 'settlement',
            component: () => import(/* webpackChunkName: "Settlement" */ '../views/MerchantSettlementRecordView.vue')
          },
          {
            path: '/walletJournal',
            name: 'walletJournal',
            component: () => import(/* webpackChunkName: "WalletJournal" */ '../views/MerchantWalletJournalView.vue')
          },
          {
            path: '/withdraw',
            name: 'withdraw',
            component: () => import(/* webpackChunkName: "Withdraw" */ '../views/MerchantWithdrawRecordView.vue')
          },
          {
            path: '/recharge',
            name: 'recharge',
            component: () => import(/* webpackChunkName: "Recharge" */ '../views/MerchantRechargeRecordView.vue')
          },
        ]
      },
      {
        path: '/system',
        name: 'system',
        redirect: '/adminUser',
        children: [
          {
            path: '/adminUser',
            name: 'adminUser',
            component: () => import(/* webpackChunkName: "AdminUser" */ '../views/AdminUserView.vue')
          },
          {
            path: '/role',
            name: 'role',
            component: () => import(/* webpackChunkName: "Role" */ '../views/PermissionView.vue')
          },
          {
            path: '/permission',
            name: 'permission',
            component: () => import(/* webpackChunkName: "Permission" */ '../views/PermissionView.vue')
          },

        ]
      },
      {
        path: '/merchart',
        name: 'merchart',
        component: () => import(/*webpackChunkName:'Merchart'*/ '../views/MerchartView.vue')
      },
      {
        path: '/paymentOrder',
        name: 'paymentOrder',
        component: () => import(/*webpackChunkName:'PaymentOrder'*/ '../views/PaymentOrderView.vue')
      },
      {
        path: '/transferOrder',
        name: 'transferOrder',
        component: () => import(/*webpackChunkName:'TransferOrder'*/ '../views/TransferOrderView.vue')
      }
    ]
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  // 刷新页面会报404，开发阶段解决方案，参考资料：https://juejin.cn/post/7080886221882261518
  history: createWebHashHistory(),
  routes
})

// 前置路由守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next();
  } else {
    let isLogin = LocalStorageManager.loginState();
    if (!isLogin) {
      next('/login');
    } else {
      next();
    }
  }
});

export default router
